import React, {Component} from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import Menu from './Menu';
import MobileMenu from './MobileMenu';

class Header extends Component {
  render () {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let title = this.props.title && (this.props.title + " || ") || "";
    title = capitalizeFirstLetter(title);

    title += "Art Lovers";

    return (
        <>
            <Helmet>
                <title>{ title }</title>
            </Helmet>
            <div className="site-mobile-menu">
                <div className="site-mobile-menu-header">
                <div className="site-mobile-menu-close mt-3">
                    <span className="icon-close2 js-menu-toggle"></span>
                </div>
                </div>
                <div className="site-mobile-menu-body">
                    <MobileMenu />
                </div>
            </div>
            <header className="site-navbar py-3" role="banner">

                <div className="container-fluid">
                <div className="row align-items-center">
                    
                    <div className="col-6 col-xl-2" data-aos="fade-down">
                    <Link to="/" className="text-black h2 mb-0"><img width="50%"  src="/logo.png"/></Link>
                    </div>
                    <div className="col-10 col-md-8 d-none d-xl-block" data-aos="fade-down">
                    <nav className="site-navigation position-relative text-right text-lg-center" role="navigation">
                        <Menu />
                    </nav>
                    </div>

                    <div className="col-6 col-xl-2 text-right" data-aos="fade-down">
                    <div className="d-none d-xl-inline-block">
                        <ul className="site-menu js-clone-nav ml-auto list-unstyled d-flex text-right mb-0" data-class="social">
                        <li>
                            <a href="#" className="pl-0 pr-3"><span className="icon-facebook"></span></a>
                        </li>
                        <li>
                            <a href="#" className="pl-3 pr-3"><span className="icon-twitter"></span></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/artlovers.in/" className="pl-3 pr-3"><span className="icon-instagram"></span></a>
                        </li>
                        <li>
                            <a href="#" className="pl-3 pr-3"><span className="icon-youtube-play"></span></a>
                        </li>
                        </ul>
                    </div>

                    <div className="d-inline-block d-xl-none ml-md-0 mr-auto py-3" style={{position: "relative", top: "3px"}}><a href="#" className="site-menu-toggle js-menu-toggle text-black"><span className="icon-menu h3"></span></a></div>

                    </div>

                </div>
                </div>
            
            </header>
        </>
    )}
}

export default Header;