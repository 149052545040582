import React from 'react';

const Image = ({folder, name, description }) => {
  const topFolder = "/images/list/";

  return (
    <div
        className="col-sm-6 col-md-4 col-lg-3 col-xl-2 item p-1"
        data-aos="fade" data-src={ topFolder + "original/" + folder + name}
        data-sub-html={"<h3>"+description+"</h3>"}
        // data-responsive={`${topFolder + folder + name} 375`}
    >
        <a href="#">
          <img
            src={topFolder + folder + name}
            alt="Image"
            className="img-fluid"
            style={{border: "18px solid transparent", borderColor: "#F0F0F0", borderRadius: "5px"}}
          />
        </a>
        <div className="caption">
          <h5 style={{ textAlign: "center"}}>{description}</h5>
        </div>
    </div>
  );
}

export default Image;