import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header'
import styles from "./home.module.css";

class Home extends Component {
  render () {
    const topFolder = "/images/list/original/";
    const categories = [
      {
        name: "Pencil Drawing", href: "pencil", imageSrc: topFolder + "pencil/img-3.jpg", description: "The pencil is the most important tool when it comes to drawing and sketching. There are different grades, qualities, and types of pencils available for this artwork. Pencils are a dry medium, in contrast to pens which apply liquids."
      },
      {
        name: "Color Pencil Drawing", href: "color-pencil", imageSrc: topFolder + "color-pencil/img-9.jpg", description: "A colored pencil is an art medium constructed of a narrow, pigmented core encased in a wooden cylindrical case. Unlike graphite and charcoal pencils, colored pencils' cores are wax- or oil-based and contain varying proportions of pigments, additives, and binding agents."
      },
      {
        name: "Acrylic Painting", href: "acrylic", imageSrc: topFolder + "acrylic/img-2.jpg", description: "Acrylic painting is executed in the medium of synthetic acrylic resins. Acrylics dry rapidly, serve as a vehicle for any kind of pigment, and are capable of giving both the transparent brilliance of watercolour and the density of oil paint. Acrylics can be used on paper, canvas and a range of other materials."
      },
      {
        name: "Oil Pastels", href: "oil-pastel", imageSrc: topFolder + "oil-pastel/img-2.jpg", description: "Oil pastels are a unique drawing/painting medium for creating color art. Oil pastels behave in a similar fashion to traditional soft pastels, but they also possess characteristics that are similar to that of crayons."
      },
      {
        name: "Paper Quilling", href: "paper-quilling", imageSrc: topFolder + "paper-quilling/img-3.jpg", description: "Quilling is the art of manipulating and arranging small strips of paper into detailed designs. Depending on the desired shape and appearance, it can be rolled, looped, twisted, and curled. Glue is used to secure the paper strips into place."
      },
      {
        name: "Madhubani Art", href: "madhubani", imageSrc: topFolder + "madhubani/img-2.jpg", description: "The Madhubani painting is said to be one of the most famous art forms that originated from India. These paintings often have characteristics like complex geometrical patterns. They are also well known for representation of ritual content in special occasions like festivals and religious rituals."
      }
    ]

    return (
      <>
        <Header/>
        <div className={styles.siteContainer}>
        {
          categories.map((category, index) => {
            return <div key={category.name} className={`${styles.container} ${index % 2 && styles.leftSide || styles.leftSide}`}>
              <img className={styles.image} src={category.imageSrc}/>
                  <div className={styles.contentDiv}>
                    <h2 className={styles.contenTitle}>{category.name}</h2>
                    <p className={styles.contentDescription}>{category.description}</p>
                    <Link to={'/arts/' + category.href}><button className={styles.seeMoreBtn}>See more</button></Link>
                </div>
            </div>
          })
        }
        </div>
      </>
    );
  }
}

export default Home;
