import React, {Component} from 'react';
import Routes from './Routes'

class App extends Component {
  render () {
    return (
      <Routes/>
    );
  }
}

export default App;
