import React from "react";
import { useLocation, NavLink } from 'react-router-dom';
import { menuData } from './MenuData';

export default function Menu () {
    const location = useLocation()
    const pathname =  location.pathname
    const activeClassName = ' active'

    const isActive = (links) => {
        const index = links.findIndex( link => link.href === pathname)

        return index === -1 ? false: true;
    }

    const Link = link => (
        <li key={link.href} className={pathname===link.href ? activeClassName: ''}>
            <NavLink to={link.href}>{link.text}</NavLink>
            {/* <a href={link.href}>{link.text}</a> */}
        </li>
    )

    return <ul className="site-menu js-clone-nav mx-auto d-none d-lg-block">
        {
            menuData.map( item => {
                if (typeof item.href ==='string') {
                    return Link(item)
                }

                if (typeof item.href === 'object') {
                    return <li key={item.href} className={ 'has-children' + (isActive(item.href) ? activeClassName: '')}>
                        <a href={'#'}>{item.text}</a>
                        <ul className="dropdown">
                            {
                                item.href.map(anchor => Link(anchor))
                            }
                        </ul>
                    </li>
                }
            })
        }
        </ul>
}