import React from "react";
import { useLocation, NavLink } from 'react-router-dom';
import { menuData } from './MenuData';

export default function Menu () {
    const location = useLocation()
    const pathname =  location.pathname
    const activeClassName = ' active'

    const isActive = (links) => {
        const index = links.findIndex( link => link.href === pathname)

        return index === -1 ? false: true;
    }

    const Link = link => (
        <li key={link.href} className={pathname===link.href ? activeClassName: ''}>
            <NavLink className={'mobileMenuLink'} to={link.href}>{link.text}</NavLink>
        </li>
    )

    let counter = 0;

    return <>
        <ul className="site-nav-wrap">
        {
            menuData.map( item => {
                if (typeof item.href ==='string') {
                    return Link(item)
                }

                if (typeof item.href === 'object') {
                    return <li key={item.href} className={ 'has-children' + (isActive(item.href) ? activeClassName: '')}>
                        <span className="arrow-collapse collapsed" data-toggle="collapse" data-target={"#collapseItem" + counter} />
                        <a href={'#'}>{item.text}</a>
                        <ul className="collapse" id={"collapseItem" + counter++}>
                            {
                                item.href.map(anchor => Link(anchor))
                            }
                        </ul>
                    </li>
                }
            })
        }
        </ul>

        <ul className="site-nav-wrap" data-class="social">
            <li>
                <a href="#" className="pl-0 pr-3"><span className="icon-facebook"></span></a>
            </li>
            <li>
                <a href="#" className="pl-3 pr-3"><span className="icon-twitter"></span></a>
            </li>
            <li>
                <a href="https://www.instagram.com/artlovers.in/" className="pl-3 pr-3"><span className="icon-instagram"></span></a>
            </li>
            <li>
                <a href="#" className="pl-3 pr-3"><span className="icon-youtube-play"></span></a>
            </li>
        </ul>
    </>
}