import React from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

import Home from './pages/home';
import Arts from './pages/arts';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route path='/arts/:type' component={Arts}></Route>

        {/* Redirect all 404's to home */}
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;