import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Image from '../components/arts/Image';
import { connect } from 'react-redux';
import $ from "jquery";
import 'lightgallery';

class Arts extends Component {
    onLightGallery = node => {
        this.lightGallery = node;
        $(node).lightGallery();
    }

    componentWillUnmount() {
        $(this.lightGallery).data('lightGallery').destroy(true);
    }

  render () {
    const data = this.props.arts[this.props.match.params.type];

    if (!data)
        return <Redirect to='/'/>;
    
    const folder = data.folder;
    const images = [...data.images];
    images.reverse();

    return (
        <>
            <div className="site-wrap">
                <Header title = {this.props.match.params.type}/>
                <div className="site-section"  data-aos="fade">
                    <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                        <div className="row mb-5">
                            <div className="col-12 ">
                            <h2 className="site-section-heading text-center">{data.type} Arts</h2>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row" id="lightgallery" ref={this.onLightGallery}>
                        {
                            images.map( (img, index) => <Image key = {index} folder = {folder} {...img} />)
                        }
                    </div>
                    </div>
                </div>
            </div>
      </>
    );
  }
}

const mapStateToProp = state => {
    return {
        arts: state.arts
    }
}

export default connect(mapStateToProp)(Arts);
