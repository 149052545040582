const artsPath = '/arts/';

export const menuData = [
    {  text: 'Home', href: '/' },
    { 
        text: 'Drawing',
        href: [
            {
                text: 'Madhubani',
                href: artsPath + 'madhubani'
            },
            {
                text: 'Pencil',
                href: artsPath + 'pencil'
            },
            {
                text: 'Color Pencil',
                href: artsPath + 'color-pencil'
            },
            {
                text: 'Oil Pastel',
                href: artsPath + 'oil-pastel'
            }
        ]
    },
    {
        text: 'Painting',
        href: [
            {
                text: 'Acrylic',
                href: artsPath + 'acrylic'
            },
            {
                text: 'Wall',
                href: artsPath + 'wall'
            }
        ]
    },
    {
        text: 'Crafts',
        href: [
            {
                text: 'Paper Quilling',
                href: artsPath + 'paper-quilling'
            }
        ]
    },
    {
        text: 'Others',
        href: artsPath + 'others'
    }
];
